
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.media-preview__container::v-deep {
    padding: 0;

    .media-preview__wrapper {
        .media-preview__media {
            height: 11em;
            position: relative;
            cursor: pointer;

            .media-preview-list__actions {
                position: absolute;
                height: 90%;
                width: 93%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0.5em;

                .media-preview-list_actions--top,
                .media-preview-list_actions--bottom {
                    display: flex;
                    flex-direction: row !important;
                    z-index: 1;
                }

                .media-preview-list__featured-btn {
                    opacity: 0.5;

                    &.featured {
                        opacity: 1;
                    }
                }
            }

            .media-preview__media-image {
                height: 100%;
            }
        }

        .media-preview__add-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 10em;
            background-color: $grey-light-darker;
            border: 1px dashed #ccc;
            border-radius: 4px;
            cursor: pointer;
            text-align: center;
        }
    }
}
