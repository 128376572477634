
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.media-library__actions--wrapper::v-deep {
    align-items: center;

    .media-library__action-buttons {
        display: flex;
        align-items: center;
        height: 4em;

        .media-library__action-button {
            color: $doger-blue;
        }
    }

    .media-library__uploader {
        .uploader-button {
            cursor: pointer;
            width: fit-content;

            &:hover {
                .label {
                    color: $primary-color;
                }
            }
        }
    }
}
