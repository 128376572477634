
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.content-editable::v-deep {
    cursor: text;
    outline: none;
    // preserve whitespace
    white-space: pre-line;

    &[contenteditable='true'] {
        &:empty:not(:focus):before {
            content: attr(placeholder);
            display: block;
            pointer-events: none;
        }
    }

    &.with-backdrop {
        &:not(.is-empty) {
            display: inline;
            line-height: 0;
            padding-bottom: 3px;
            padding-top: 3px;
        }
        background-color: var(--box-drop-color) !important;
    }

    text-shadow: var(--box-outline) var(--box-outline) #666; // #666 is hardcoded in TextRenderer
}
