
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.delete-media--wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
