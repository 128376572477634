
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.results-container::v-deep {
    align-content: flex-start;
    position: relative;
    margin: 8px 0 0;

    .media-wrapper {
        height: 10em;
        padding-left: 0;
        padding-top: 0;

        .actions {
            position: absolute;
            z-index: 1;

            .action--button {
                .v-icon {
                    background-color: white;
                    border-radius: 2px;
                    width: 16px !important;
                    height: 16px !important;
                }

                &.action-select {
                    .v-icon {
                        svg {
                            stroke: $concrete-solid !important;
                            stroke-width: 8px;
                            filter: drop-shadow(1px 1px 1px black);

                            path {
                                fill: white !important;
                            }

                            border-bottom: solid 1px black;
                            filter: drop-shadow(1px 1px 1px black);
                        }
                    }
                }

                &.action-deselect {
                    .v-icon {
                        svg {
                            stroke: $doger-blue !important;

                            path {
                                fill: $doger-blue !important;
                            }
                        }
                    }
                }
            }
        }

        .media-preview {
            height: 100%;
            cursor: pointer;
            border: $bonjour 2px solid;
            transition: all 0.2s ease;
        }

        &.selected {
            .media-preview {
                border-width: 8px;
                border-color: $faded-blue;
            }
        }
    }

    .media-loading {
        height: 10em;
        padding-left: 0;
        padding-top: 0;
    }
}
