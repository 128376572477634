
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.media-edit--container::v-deep {
    height: 62vh;

    .media-edit--wrapper {
        height: 100%;

        .media-edit--image-wrapper {
            display: flex;
            height: 100%;

            .media-edit--image {
                background-color: $bonjour;
            }
        }
    }
}
