
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.amp-video_media_box {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:after {
        /* backdrop_color box */
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--box-drop-color);
        opacity: var(--box-drop-opacity);
        z-index: -1;
    }

    .amp-media-viewport {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .with-overlay:after {
        /* overlay_background media box */
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--box-overlay-background);
    }

    img,
    video {
        width: 100%;
        height: 100%;
    }
}

.amp-video_media_box-clickable {
    cursor: pointer;

    &:hover {
        background: $vuetify-grey;
    }

    .amp-media-viewport,
    .amp-video_media_box-nofile {
        transition: all 0.2s ease;
        cursor: pointer;

        &:after {
            content: '';
            transition: 0.2s ease;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            background: var(--box-overlay-background);
        }
    }

    .amp-media-viewport:hover:after {
        opacity: 0.3;
        background: repeating-linear-gradient(
            -45deg,
            transparent,
            transparent 30px,
            white 30px,
            white 40px
        );
    }
}

.amp-video_media_box-nofile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.amp-video_media_box,
.amp-video_media_box-clickable {
    .amp-video_media_box-nofile {
        border: 3px solid $error;

        &:hover {
            background-color: $error;

            &:after {
                opacity: 0.3;
            }
        }
    }
}
