
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.media-dialog__navbar--container {
    margin-bottom: 1em;
    .media-dialog__navbar--wrapper::v-deep {
        display: flex;
        border-bottom: solid 2px $ebb;

        .media-dialog__navbar--link {
            float: left;
            padding: 1.25em;
            width: 10em;
            text-align: center;
            transition: all 0.2s ease;
            color: $doger-blue;
            text-decoration: none;

            &.active {
                background: $doger-blue;
                .v-icon {
                    svg {
                        path {
                            fill: white !important;
                            stroke: white !important;
                        }
                    }
                }
            }

            &:not(.active):hover {
                background: $faded-blue;
            }

            .v-icon {
                svg {
                    path {
                        fill: $doger-blue !important;
                        stroke: $doger-blue !important;
                    }
                }
            }
        }
    }
}

.media-dialog__content--container::v-deep {
    height: 55vh;
    overflow: hidden;
}

@media (max-width: 600px) {
    .media-dialog__navbar--container {
        .media-dialog__navbar--wrapper {
            .media-dialog__navbar--link {
                width: 50% !important;
            }
        }
    }
}
