
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.video-box {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        transition: 0.2s ease;
    }

    &.box-active {
        &:before {
            border: 5px solid green;
            background-color: var(--box-text-color);
            opacity: 0.3;
        }

        &.box-invalid:before {
            background-color: $error;
        }
    }

    &.box-invalid {
        &:before {
            border: 5px solid $error;
            opacity: 0.8;
        }
    }

    &.unchangeable {
        pointer-events: none;
    }
}
/**
    Generic styles for different types of boxes
 */
.amp-video_color_box {
    position: absolute;

    div {
        position: absolute;
    }
}

.amp-video_text_box {
    position: absolute;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 1em;
    cursor: pointer;

    &-valign-center {
        top: 50%;
        transform: translateY(-50%);

        &.amp-video_text_box-align-center {
            transform: translate(-50%, -50%);
        }
    }

    &-align-center {
        left: 50%;
        transform: translateX(-50%);
    }

    &-align-right {
        left: 100%;
        transform: translateX(-100%);

        &.amp-video_text_box-valign-center {
            transform: translate(-100%, -50%);
        }
    }
}

.with-backdrop {
    &:hover,
    &.box-active {
        .content-editable.with-backdrop {
            display: inherit;
            line-height: normal;
            padding: unset;
            padding-top: 3px;
        }
    }
}

.amp-video_media_box-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
