
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.media-tab-container::v-deep {
    height: 100%;

    .stock-container {
        height: inherit;
        align-content: flex-start;

        .search-form {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;

            .input-group {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                input {
                    padding: 17px;
                    width: 80%;
                    font-size: 1rem;
                    background-color: $main-background;
                    transition: all 0.2s ease;
                    border-radius: 4px;
                    margin-right: 1em;
                    color: $secondary-color;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        color: $secondary-color;
                    }
                }

                button {
                    width: 20%;
                    padding: 17px 0;
                    background: $doger-blue;
                    color: #f8f8f9;
                    border-radius: 4px;
                    border: none;
                    box-shadow: none;
                    font-size: large;
                    transition: all 0.2s ease;

                    &:hover {
                        opacity: 0.8;
                    }

                    &:disabled {
                        background-color: #e0e0e0;
                    }
                }
            }
        }

        .result-tabs {
            padding-top: 0;

            .v-tabs {
                .v-ripple__container {
                    display: none !important;
                }

                .v-tabs-bar {
                    height: 36px;

                    .v-tabs-slider-wrapper {
                        max-width: 52px;
                        color: $doger-blue;
                    }

                    .v-tab {
                        padding-left: 0;
                        text-transform: none;
                        min-width: 50px;
                        color: $secondary-color-light;

                        &.v-tab--active {
                            color: $doger-blue;
                        }

                        &:hover {
                            background: none;
                        }

                        &::before {
                            background-color: unset !important;
                        }
                    }
                }
            }
        }

        .results-wrapper {
            height: calc(100% - 8em);
            padding: 0;

            .result-tabs-items {
                &.v-tabs-items {
                    height: calc(100% - 2.75em);
                    overflow: auto;
                }
            }
        }

        .media-tab-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            width: 100%;
            right: 0;
            left: 0;
            bottom: 0;
            padding: 20px 24px;
        }
    }
}
