
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.media-tab-container::v-deep {
    height: 100%;
    padding-top: 0;

    .uploader-container {
        height: calc(100% - 47px);
        margin: 0;

        .uploader-wrapper {
            padding: 0;
            border-radius: 0.5em;
            border: 2px dashed #ccc;

            .media-uploader--wrapper {
                .filepond--root {
                    height: 100%;
                    align-self: auto;

                    .filepond--drop-label {
                        height: 100%;
                    }

                    .filepond--list {
                        .filepond--item {
                            width: calc(50% - 0.5em);
                        }
                    }
                }

                &.reduce-height {
                    .filepond--root {
                        .filepond--drop-label {
                            height: unset !important;
                        }
                    }
                }
            }
        }
    }

    .account-container {
        height: 100%;
        align-content: flex-start;

        .results-container {
            height: calc(100% - 6.5em);
            border: 2px dashed $bonjour;
            border-radius: 4px;
            overflow: hidden;
            padding: 20px 20px 0 20px;

            .results-wrapper {
                display: flex;
                align-content: flex-start;
                height: 100%;
                overflow: auto;
                position: relative;

                .media-wrapper {
                    height: 10em;
                    padding-left: 0;
                    padding-top: 0;

                    .actions {
                        position: absolute;
                        z-index: 1;

                        .action-button {
                            .v-icon {
                                background-color: white;
                                border-radius: 2px;
                                width: 16px !important;
                                height: 16px !important;
                            }

                            &.action-select {
                                .v-icon {
                                    svg {
                                        stroke: $concrete-solid !important;
                                        stroke-width: 8px;
                                        filter: drop-shadow(1px 1px 1px black);

                                        path {
                                            fill: white !important;
                                        }
                                    }
                                    border-bottom: solid 1px black;
                                    filter: drop-shadow(1px 1px 1px black);
                                }
                            }

                            &.action-deselect {
                                .v-icon {
                                    svg {
                                        stroke: $doger-blue !important;

                                        path {
                                            fill: $doger-blue !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .media-preview {
                        height: 100%;
                        cursor: pointer;
                        border: $bonjour 2px solid;
                        transition: all 0.2s ease;

                        &.archived {
                            opacity: 0.3;
                        }
                    }

                    &.selected {
                        .media-preview {
                            border-width: 8px;
                            border-color: $faded-blue;
                        }
                    }
                }
            }
        }

        .media-tab-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            right: 0;
            left: 0;
            bottom: 0;
            padding: 20px 24px;

            small {
                width: 75%;
            }
        }
    }
}

@media (max-width: 600px) {
    .media-tab-container {
        .account-container {
            .results-container {
                height: calc(100% - 10.25em);
            }

            .actions-wrapper {
                width: 100%;
                padding: 0 !important;
                margin-top: 4px !important;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}
