
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.media-preview-wrapper::v-deep {
    height: 62vh;

    .v-image {
        background-color: $bonjour;
        height: 100%;
    }

    .video-wrapper {
        display: flex;
        height: 100%;
        background-color: $bonjour;
        align-items: center;

        video {
            width: 100%;
            object-fit: fill;
        }
    }
}
