
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.rich-content-editable-container::v-deep {
    .rich-layer {
        pointer-events: none;
        cursor: text;
        outline: none;
        // fit entire line-height
        font-size: 85%;
        // preserve whitespace
        white-space: pre-line;

        b {
            font-family: var(--emphasis-font);
            color: var(--emphasis-color);
            text-shadow: var(--emphasis-shadow);
        }
    }

    .invisible {
        opacity: 0;
    }
}
