
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.media-tab-container::v-deep {
    height: 100%;

    .stock-container {
        height: 100%;
        align-content: flex-start;

        .media-result-container {
            height: calc(100% - 2.5em);
            border: 2px dashed $bonjour;
            border-radius: 4px;
            overflow: hidden;
            padding: 20px 20px 0 20px;

            .media-result-wrapper {
                display: flex;
                align-content: flex-start;
                height: 100%;
                overflow: auto;
                position: relative;

                .media-wrapper {
                    height: 10em;
                    padding-left: 0;
                    padding-top: 0;

                    .actions {
                        position: absolute;
                        z-index: 1;

                        .action-button {
                            .v-icon {
                                background-color: white;
                                border-radius: 2px;
                                width: 16px !important;
                                height: 16px !important;
                            }

                            &.action-select {
                                .v-icon {
                                    svg {
                                        stroke: $concrete-solid !important;
                                        stroke-width: 8px;
                                        filter: drop-shadow(1px 1px 1px black);

                                        path {
                                            fill: white !important;
                                        }
                                    }
                                }
                            }

                            &.action-deselect {
                                .v-icon {
                                    svg {
                                        stroke: $doger-blue !important;

                                        path {
                                            fill: $doger-blue !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .media-preview {
                        height: 100%;
                        cursor: pointer;
                        border: $bonjour 2px solid;
                        transition: all 0.2s ease;
                        // stock media could be white
                        // so we need a backgroud to make them visible
                        background-color: $seashell-solid;
                    }

                    &.selected {
                        .media-preview {
                            border-width: 8px;
                            border-color: $faded-blue;
                        }
                    }
                }
            }
        }

        .media-tab-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            width: 100%;
            right: 0;
            left: 0;
            bottom: 0;
            padding: 20px 24px;
        }
    }
}
